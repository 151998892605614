/* Redux exp */

import { createSelector } from 'reselect'

//CONSTANTES
const GET_EXPERIENCES = 'booker/experiences/get_experiences'
const SET_SINGLE_EXPERIENCE = 'booker/experiences/get_single_experience'
const SET_AGENCY = 'booker/experiences/set_agency'
const SET_IS_EXPERIENCE_ACTIVE = 'booker/experiences/set_is_experience_active'
const SET_HAS_SLUG = 'booker/experiences/set_has_slug'
const CLEAN_EXPERIENCES = 'booker/experiences/clean_experiences'
const SET_SETTINGS = 'booker/experiences/set_settings'
const DATA_GIFTCARD = 'booker/experiences/data_giftcard'

// ESTADO INICIAL
const initialState = {
  agencys: [],
  hotels: [],
  programs: [],
  singleExperience: {},
  agency: {},
  isExperienceActive: true,
  hasSlug: false,
  settings: {},
  isSettings: false,
  dataGiftCard: [],
}

//REDUCER
const experiencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_EXPERIENCES:
      let exp = action.payload
      console.log(exp)
      const dateHotels = exp.filter((exp) => exp.category === 'HOTEL')
      const dateAgencys = exp.filter((exp) => exp.category === 'AGENCY')
      const datePrograms = exp.filter(
        (exp) => exp.category === 'PROGRAM' || exp.category === 'TAILORMADE',
      )
      console.log(datePrograms)
      return {
        ...state,
        agencys: dateAgencys,
        hotels: dateHotels,
        programs: datePrograms,
      }
    case SET_SINGLE_EXPERIENCE:
      return { ...state, singleExperience: action.payload }
    case SET_AGENCY:
      return { ...state, agency: action.payload }
    case SET_IS_EXPERIENCE_ACTIVE:
      return { ...state, isExperienceActive: action.payload }
    case SET_HAS_SLUG:
      return { ...state, hasSlug: action.payload }
    case CLEAN_EXPERIENCES:
      return { ...initialState }
    case SET_SETTINGS:
      return { ...state, settings: action.payload }
    case DATA_GIFTCARD:
      console.log(action.payload)
      return { ...state, dataGiftCard: action.payload }
    default:
      return state
  }
}

export default experiencesReducer

//ACTIONS
export const get_experiences = (experiences) => ({
  type: GET_EXPERIENCES,
  payload: experiences,
})

export const set_single_experience = (experience) => ({
  type: SET_SINGLE_EXPERIENCE,
  payload: experience,
})

export const set_agency = (agency) => ({
  type: SET_AGENCY,
  payload: agency,
})

export const set_is_experience_active = (isExperienceActive) => ({
  type: SET_IS_EXPERIENCE_ACTIVE,
  payload: isExperienceActive,
})

export const set_has_slug = (payload) => ({
  type: SET_HAS_SLUG,
  payload: payload,
})

export const clean_experiences = () => ({
  type: CLEAN_EXPERIENCES,
})

export const set_settings = (settings) => ({
  type: SET_SETTINGS,
  payload: settings,
})
export const data_giftcard = (data) => ({
  type: DATA_GIFTCARD,
  payload: data,
})

//SELECTORS
const experiencesState = (state) => state.experiencesReducer

export const makeSelectExperiences = createSelector(
  experiencesState,
  (experiences) => experiences.experiences,
)

export const makeSelectAgencys = createSelector(
  experiencesState,
  (experiences) => experiences.agencys,
)

export const makeSelectHotels = createSelector(
  experiencesState,
  (experiences) => experiences.hotels,
)

export const makeSelectPrograms = createSelector(
  experiencesState,
  (experiences) => experiences.programs,
)

export const makeSelectSingleExperience = createSelector(
  experiencesState,
  (experiences) => experiences.singleExperience,
)

export const makeSelectAgency = createSelector(
  experiencesState,
  (experiences) => experiences.agency,
)

export const makeSelectIsExperienceActive = createSelector(
  experiencesState,
  (experiences) => experiences.isExperienceActive,
)

export const makeSelectHasSlug = createSelector(
  experiencesState,
  (experiences) => experiences.hasSlug,
)

export const makeSelectSettings = createSelector(
  experiencesState,
  (experiences) => experiences.settings,
)
export const makeSelectDataGiftCard = createSelector(
  experiencesState,
  (experiences) => experiences.dataGiftCard,
)
