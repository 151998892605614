import { createSelector } from 'reselect'

//CONSTANTES

const SET_NAME = 'booker/passengers/set_name'
const SET_LASTNAME = 'booker/passengers/set_lastName'
const SET_ORIGIN = 'booker/passengers/set_origin'
const SET_PLACE_LIVE = 'booker/passengers/set_place_live'
const SET_EMAIL = 'booker/passengers/set_email'
const SET_RUT = 'booker/passengers/set_rut'
const SET_NATIONALITY = 'booker/passengers/set_nationality'
const SET_SOCIAL_SECURITY = 'booker/passengers/set_social_security'
const SET_HEALTHCARE_CENTER = 'booker/passengers/set_healthcare_center'
const SET_PASSENGER_HEIGHT = 'booker/passengers/set_passenger_height'
const SET_PASSENGER_WEIGHT = 'booker/passengers/set_passenger_weight'
const SET_BIRTH_DATE = 'booker/passengers/set_birth_date'
const SET_PREVIOUS_EXPERIENCE = 'booker/passengers/set_previous_experience'
const SET_PREVIOUS_EXPERIENCE_RESPONSE =
  'booker/passengers/set_previous_experience_response'
const SET_ALLERGIES = 'booker/passengers/set_allergies'
const SET_ALLERGIES_RESPONSE = 'booker/passengers/set_allergies_response'
const SET_MEDICATIONS = 'booker/passengers/set_medications'
const SET_MEDICATIONS_RESPONSE = 'booker/passengers/set_medications_response'
const SET_SPECIAL_DIET = 'booker/passengers/set_special_diet'
const SET_SPECIAL_DIET_RESPONSE = 'booker/passengers/set_special_diet_reponse'
const SET_MEDICAL_SURGERIES = 'booker/passengers/set_medial_surgeries'
const SET_MEDICAL_SURGERIES_RESPONSE =
  'booker/passengers/set_medical_surgeries_response'
const SET_PREGNANCY = 'booker/passengers/set_pregnancy'
const SET_PREGNANCY_RESPONSE = 'booker/passengers/set_pregnancy_response'
const SET_INSURANCE = 'booker/passengers/set_insurance'
const SET_INSURANCE_RESPONSE = 'booker/passengers/set_insurance_response'
const SET_NAME_EMERGENCY = 'booker/passengers/set_name_emergency'
const SET_LASTNAME_EMERGENCY = 'booker/passengers/set_lastname_emergency'
const SET_PHONE_EMERGENCY = 'booker/passengers/set_phone_emergency'
const CLEAN_PASSENGERS = 'booker/passengers/clean_passengers'

//ESTADO INICIAL
const initialState = {
  name: '',
  lastName: '',
  origin: '',
  placeLive: '',
  email: '',
  rut: '',
  nationality: '',
  socialSecurity: '',
  healthcareCenter: '',
  passengerHeight: 0,
  passengerWeight: 0,
  birthDate: null,
  previousExperience: 'No',
  previousExperienceResponse: '',
  allergies: 'No',
  allergiesResponse: '',
  medications: 'No',
  medicationsResponse: '',
  specialDiet: 'No',
  specialDietResponse: '',
  medicalSurgeries: 'No',
  medicalSurgeriesResponse: '',
  pregnancy: 'No',
  pregnancyResponse: '',
  insurance: 'No',
  insuranceResponse: '',
  nameEmergency: '',
  lastNameEmergency: '',
  phoneEmergency: '',
}

//REDUCER
const passengersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAME:
      return { ...state, name: action.payload }
    case SET_LASTNAME:
      return { ...state, lastName: action.payload }
    case SET_ORIGIN:
      return { ...state, origin: action.payload }
    case SET_PLACE_LIVE:
      return { ...state, placeLive: action.payload }
    case SET_EMAIL:
      return { ...state, email: action.payload }
    case SET_RUT:
      return { ...state, rut: action.payload }
    case SET_NATIONALITY:
      return { ...state, nationality: action.payload }
    case SET_SOCIAL_SECURITY:
      return { ...state, socialSecurity: action.payload }
    case SET_HEALTHCARE_CENTER:
      return { ...state, healthcareCenter: action.payload }
    case SET_PASSENGER_HEIGHT:
      return { ...state, passengerHeight: action.payload }
    case SET_PASSENGER_WEIGHT:
      return { ...state, passengerWeight: action.payload }
    case SET_BIRTH_DATE:
      return { ...state, birthDate: action.payload }
    case SET_PREVIOUS_EXPERIENCE:
      return { ...state, previousExperience: action.payload }
    case SET_PREVIOUS_EXPERIENCE_RESPONSE:
      return { ...state, previousExperienceResponse: action.payload }
    case SET_ALLERGIES:
      return { ...state, allergies: action.payload }
    case SET_ALLERGIES_RESPONSE:
      return { ...state, allergiesResponse: action.payload }
    case SET_MEDICATIONS:
      return { ...state, medications: action.payload }
    case SET_MEDICATIONS_RESPONSE:
      return { ...state, medicationsResponse: action.payload }
    case SET_SPECIAL_DIET:
      return { ...state, specialDiet: action.payload }
    case SET_SPECIAL_DIET_RESPONSE:
      return { ...state, specialDietResponse: action.payload }
    case SET_MEDICAL_SURGERIES:
      return { ...state, medicalSurgeries: action.payload }
    case SET_MEDICAL_SURGERIES_RESPONSE:
      return { ...state, medicalSurgeriesResponse: action.payload }
    case SET_PREGNANCY:
      return { ...state, pregnancy: action.payload }
    case SET_PREGNANCY_RESPONSE:
      return { ...state, pregnancyResponse: action.payload }
    case SET_INSURANCE:
      return { ...state, insurance: action.payload }
    case SET_INSURANCE_RESPONSE:
      return { ...state, insuranceResponse: action.payload }
    case SET_NAME_EMERGENCY:
      return { ...state, nameEmergency: action.payload }
    case SET_LASTNAME_EMERGENCY:
      return { ...state, lastNameEmergency: action.payload }
    case SET_PHONE_EMERGENCY:
      return { ...state, phoneEmergency: action.payload }
    case CLEAN_PASSENGERS:
      return { ...initialState }
    default:
      return state
  }
}

export default passengersReducer

//ACTIONS
export const set_name = (payload) => ({
  type: SET_NAME,
  payload,
})

export const set_lastName = (payload) => ({
  type: SET_LASTNAME,
  payload,
})

export const set_origin = (payload) => ({
  type: SET_ORIGIN,
  payload,
})

export const set_placeLive = (payload) => ({
  type: SET_PLACE_LIVE,
  payload,
})

export const set_email = (payload) => ({
  type: SET_EMAIL,
  payload,
})

export const set_rut = (payload) => ({
  type: SET_RUT,
  payload,
})

export const set_nationality = (payload) => ({
  type: SET_NATIONALITY,
  payload,
})

export const set_social_security = (payload) => ({
  type: SET_SOCIAL_SECURITY,
  payload,
})

export const set_healthcare_center = (payload) => ({
  type: SET_HEALTHCARE_CENTER,
  payload,
})

export const set_passenger_height = (payload) => ({
  type: SET_PASSENGER_HEIGHT,
  payload,
})

export const set_passenger_weight = (payload) => ({
  type: SET_PASSENGER_WEIGHT,
  payload,
})

export const set_birth_date = (payload) => ({
  type: SET_BIRTH_DATE,
  payload,
})

export const set_previous_experience = (payload) => ({
  type: SET_PREVIOUS_EXPERIENCE,
  payload,
})

export const set_previous_experience_response = (payload) => ({
  type: SET_PREVIOUS_EXPERIENCE_RESPONSE,
  payload,
})

export const set_allergies = (payload) => ({
  type: SET_ALLERGIES,
  payload,
})

export const set_allergies_response = (payload) => ({
  type: SET_ALLERGIES_RESPONSE,
  payload,
})

export const set_medications = (payload) => ({
  type: SET_MEDICATIONS,
  payload,
})

export const set_medications_response = (payload) => ({
  type: SET_MEDICATIONS_RESPONSE,
  payload,
})

export const set_special_diet = (payload) => ({
  type: SET_SPECIAL_DIET,
  payload,
})

export const set_special_diet_response = (payload) => ({
  type: SET_SPECIAL_DIET_RESPONSE,
  payload,
})

export const set_medical_surgeries = (payload) => ({
  type: SET_MEDICAL_SURGERIES,
  payload,
})

export const set_medical_surgeries_response = (payload) => ({
  type: SET_MEDICAL_SURGERIES_RESPONSE,
  payload,
})

export const set_pregnancy = (payload) => ({
  type: SET_PREGNANCY,
  payload,
})

export const set_pregnancy_response = (payload) => ({
  type: SET_PREGNANCY_RESPONSE,
  payload,
})

export const set_insurance = (payload) => ({
  type: SET_INSURANCE,
  payload,
})

export const set_insurance_response = (payload) => ({
  type: SET_INSURANCE_RESPONSE,
  payload,
})

export const set_name_emergency = (payload) => ({
  type: SET_NAME_EMERGENCY,
  payload,
})

export const set_lastname_emergency = (payload) => ({
  type: SET_LASTNAME_EMERGENCY,
  payload,
})

export const set_phone_emergency = (payload) => ({
  type: SET_PHONE_EMERGENCY,
  payload,
})

export const clean_passengers = () => ({
  type: CLEAN_PASSENGERS,
})

//SELECTORS
const passengersState = (state) => state.passengersReducer

export const makeSelectName = createSelector(
  passengersState,
  (passengers) => passengers.name,
)

export const makeSelectLastName = createSelector(
  passengersState,
  (passengers) => passengers.lastName,
)
export const makeSelectOrigin = createSelector(
  passengersState,
  (passengers) => passengers.origin,
)

export const makeSelectPlaceLive = createSelector(
  passengersState,
  (passengers) => passengers.placeLive,
)

export const makeSelectEmail = createSelector(
  passengersState,
  (passengers) => passengers.email,
)

export const makeSelectRut = createSelector(
  passengersState,
  (passengers) => passengers.rut,
)

export const makeSelectNationality = createSelector(
  passengersState,
  (passengers) => passengers.nationality,
)

export const makeSelectSocialSecurity = createSelector(
  passengersState,
  (passengers) => passengers.socialSecurity,
)

export const makeSelectHealthcareCenter = createSelector(
  passengersState,
  (passengers) => passengers.healthcareCenter,
)

export const makeSelectPassengerHeight = createSelector(
  passengersState,
  (passengers) => passengers.passengerHeight,
)

export const makeSelectPassengerWeight = createSelector(
  passengersState,
  (passengers) => passengers.passengerWeight,
)

export const makeSelectBirthDate = createSelector(
  passengersState,
  (passengers) => passengers.birthDate,
)

export const makeSelectPreviousExperience = createSelector(
  passengersState,
  (passengers) => passengers.previousExperience,
)

export const makeSelectPreviousExperienceResponse = createSelector(
  passengersState,
  (passengers) => passengers.previousExperienceResponse,
)

export const makeSelectallergies = createSelector(
  passengersState,
  (passengers) => passengers.allergies,
)

export const makeSelectallergiesResponse = createSelector(
  passengersState,
  (passengers) => passengers.allergiesResponse,
)

export const makeSelectmedications = createSelector(
  passengersState,
  (passengers) => passengers.medications,
)

export const makeSelectmedicationsResponse = createSelector(
  passengersState,
  (passengers) => passengers.medicationsResponse,
)

export const makeSelectspecialDiet = createSelector(
  passengersState,
  (passengers) => passengers.specialDiet,
)

export const makeSelectspecialDietResponse = createSelector(
  passengersState,
  (passengers) => passengers.specialDietResponse,
)

export const makeSelectmedicalSurgeries = createSelector(
  passengersState,
  (passengers) => passengers.medicalSurgeries,
)

export const makeSelectmedicalSurgeriesResponse = createSelector(
  passengersState,
  (passengers) => passengers.medicalSurgeriesResponse,
)

export const makeSelectpregnancy = createSelector(
  passengersState,
  (passengers) => passengers.pregnancy,
)

export const makeSelectpregnancyResponse = createSelector(
  passengersState,
  (passengers) => passengers.pregnancyResponse,
)

export const makeSelectinsurance = createSelector(
  passengersState,
  (passengers) => passengers.insurance,
)

export const makeSelectinsuranceResponse = createSelector(
  passengersState,
  (passengers) => passengers.insuranceResponse,
)

export const makeSelectnameEmergency = createSelector(
  passengersState,
  (passengers) => passengers.nameEmergency,
)

export const makeSelectlastNameEmergency = createSelector(
  passengersState,
  (passengers) => passengers.lastNameEmergency,
)

export const makeSelectphoneEmergency = createSelector(
  passengersState,
  (passengers) => passengers.phoneEmergency,
)
