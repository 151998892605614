import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ThemeProvider } from '@material-ui/core/styles'
import { theme } from './utils/theme'
import store from './redux/store'
import { Scrollbars } from 'react-custom-scrollbars'
import { StrictMode } from 'react'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Scrollbars style={{ width: '100%', height: '100vh' }}>
        <App />
      </Scrollbars>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
