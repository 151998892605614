import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
  palette: {
    primary: {
      light: "#895BD9",
      main: "#673AB7",
      dark: "#491E94",
    },
    secondary: {
      light: "#DFB50F",
      main: "#DFB50F",
      dark: "#D4AE18",
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
  },
});
