import { createSelector } from 'reselect'

//CONSTANTES
const SET_AGENCY_URL = 'booker/signup/set_agency_url'
const SET_EMAIL = 'booker/signup/set_email'
const SET_LOGO_IMAGE = 'booker/signup/set_logo_image'
const SET_BANNER_IMAGE = 'booker/signup/set_banner_image'
const SET_AGENCY_NAME = 'booker/signup/set_agency_name'
const SET_USER_NAME = 'booker/signup/set_user_name'
const SET_USER_LAST_NAME = 'booker/signup/set_user_last_name'
const SET_RUT = 'booker/signup/set_rut'
const SET_PHONE_NUMBER = 'booker/signup/set_phone_number'
const SET_COMPANY_ADDRESS = 'booker/signup/set_company_address'
const SET_BANNER_MODEL = 'booker/signup/set_banner_model'
const SET_EXPERIENCES_MODEL = 'booker/signup/set_experiences_model'
const SET_AGENCY = 'booker/signup/set_agency'
const SET_SIGNUP_COMPLETED = 'booker/signup/set_signup_completed'
const SET_BAR_ANIMATION = 'booker/signup/set_bar_animation'
const SET_BANNER_ANIMATION = 'booker/signup/set_banner_animation'
const SET_LOGO_ANIMATION = 'booker/signup/set_logo_animation'
const CLEAN_SIGNUP = 'booker/signup/clean_signup'

//ESTADO INICIAL
const initialState = {
  agencyUrl: '',
  email: '',
  logoImage: {
    url: '',
    name: '',
  },
  bannerImage: {
    url: '',
    name: '',
  },
  agencyName: '',
  userName: '',
  userLastName: '',
  rut: '',
  phoneNumber: '',
  companyAddress: {},
  bannerModel: 1,
  experiencesModel: 1,
  agency: {},
  signupCompleted: false,
  barAnimation: false,
  bannerAnimation: false,
  logoAnimation: false,
}

//REDUCER
const signupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AGENCY_URL:
      return { ...state, agencyUrl: action.payload }
    case SET_EMAIL:
      return { ...state, email: action.payload }
    case SET_LOGO_IMAGE:
      return { ...state, logoImage: action.payload }
    case SET_BANNER_IMAGE:
      return { ...state, bannerImage: action.payload }
    case SET_AGENCY_NAME:
      return { ...state, agencyName: action.payload }
    case SET_USER_NAME:
      return { ...state, userName: action.payload }
    case SET_USER_LAST_NAME:
      return { ...state, userLastName: action.payload }
    case SET_RUT:
      return { ...state, rut: action.payload }
    case SET_PHONE_NUMBER:
      return { ...state, phoneNumber: action.payload }
    case SET_COMPANY_ADDRESS:
      return { ...state, companyAddress: action.payload }
    case SET_BANNER_MODEL:
      return { ...state, bannerModel: action.payload }
    case SET_EXPERIENCES_MODEL:
      return { ...state, experiencesModel: action.payload }
    case SET_AGENCY:
      return { ...state, agency: action.payload }
    case SET_SIGNUP_COMPLETED:
      return { ...state, signupCompleted: action.payload }
    case SET_BAR_ANIMATION:
      return { ...state, barAnimation: action.payload }
    case SET_BANNER_ANIMATION:
      return { ...state, bannerAnimation: action.payload }
    case SET_LOGO_ANIMATION:
      return { ...state, logoAnimation: action.payload }
    case CLEAN_SIGNUP:
      return { ...initialState }
    default:
      return state
  }
}

export default signupReducer

//ACTIONS

export const set_agency_url = (payload) => ({
  type: SET_AGENCY_URL,
  payload,
})

export const set_email = (payload) => ({
  type: SET_EMAIL,
  payload,
})

export const set_logo_image = (payload) => ({
  type: SET_LOGO_IMAGE,
  payload,
})

export const set_banner_image = (payload) => ({
  type: SET_BANNER_IMAGE,
  payload,
})

export const set_agency_name = (payload) => ({
  type: SET_AGENCY_NAME,
  payload,
})

export const set_user_name = (payload) => ({
  type: SET_USER_NAME,
  payload,
})

export const set_user_last_name = (payload) => ({
  type: SET_USER_LAST_NAME,
  payload,
})

export const set_rut = (payload) => ({
  type: SET_RUT,
  payload,
})

export const set_phone_number = (payload) => ({
  type: SET_PHONE_NUMBER,
  payload,
})

export const set_company_address = (payload) => ({
  type: SET_COMPANY_ADDRESS,
  payload,
})

export const set_banner_model = (payload) => ({
  type: SET_BANNER_MODEL,
  payload,
})

export const set_experiences_model = (payload) => ({
  type: SET_EXPERIENCES_MODEL,
  payload,
})

export const set_agency = (payload) => ({
  type: SET_AGENCY,
  payload,
})

export const set_signup_completed = (payload) => ({
  type: SET_SIGNUP_COMPLETED,
  payload,
})

export const set_bar_animation = (payload) => ({
  type: SET_BAR_ANIMATION,
  payload,
})

export const set_banner_animation = (payload) => ({
  type: SET_BANNER_ANIMATION,
  payload,
})

export const set_logo_animation = (payload) => ({
  type: SET_LOGO_ANIMATION,
  payload,
})

export const clean_signup = (payload) => ({
  type: CLEAN_SIGNUP,
})

//SELECTORS
const signupState = (state) => state.signupReducer

export const makeSelectAgencyUrl = createSelector(
  signupState,
  (signup) => signup.agencyUrl,
)

export const makeSelectEmail = createSelector(
  signupState,
  (signup) => signup.email,
)

export const makeSelectLogoImage = createSelector(
  signupState,
  (signup) => signup.logoImage,
)

export const makeSelectBannerImage = createSelector(
  signupState,
  (signup) => signup.bannerImage,
)

export const makeSelectAgencyName = createSelector(
  signupState,
  (signup) => signup.agencyName,
)

export const makeSelectUserName = createSelector(
  signupState,
  (signup) => signup.userName,
)

export const makeSelectUserLastName = createSelector(
  signupState,
  (signup) => signup.userLastName,
)

export const makeSelectRut = createSelector(signupState, (signup) => signup.rut)

export const makeSelectPhoneNumber = createSelector(
  signupState,
  (signup) => signup.phoneNumber,
)

export const makeSelectCompanyAddress = createSelector(
  signupState,
  (signup) => signup.companyAddress,
)

export const makeSelectBannerModel = createSelector(
  signupState,
  (signup) => signup.bannerModel,
)

export const makeSelectExperiencesModel = createSelector(
  signupState,
  (signup) => signup.experiencesModel,
)

export const makeSelectAgency = createSelector(
  signupState,
  (signup) => signup.agency,
)

export const makeSelectSignupCompleted = createSelector(
  signupState,
  (signup) => signup.signupCompleted,
)

export const makeSelectBarAnimation = createSelector(
  signupState,
  (signup) => signup.barAnimation,
)

export const makeSelectBannerAnimation = createSelector(
  signupState,
  (signup) => signup.bannerAnimation,
)

export const makeSelectLogoAnimation = createSelector(
  signupState,
  (signup) => signup.logoAnimation,
)
