import { createSelector } from "reselect";

//CONSTANTES
const SET_START_DATE = "booker/search/set_start_date";
const SET_END_DATE = "booker/search/set_end_date";
const SET_LOCATION = "booker/search/set_location";
const SET_TIPO_EXPERIENCIA = "booker/search/set_tipo_experiencia";
const SET_SEARCH_START_DATE = "booker/search/set_search_start_date";
const SET_SEARCH_END_DATE = "booker/search/set_search_end_date";
const SET_SEARCH_LOCATION = "booker/search/set_search_location";
const SET_SEARCH_TIPO_EXPERIENCIA = "booker/search/set_search_tipo_experiencia";
const CLEAN_SEARCH = "booker/search/clean_search";

//ESTADO INCIAL
const initialState = {
  startDate: null,
  endDate: null,
  location: "ALL",
  tipoExperiencia: "ALL",
  searchStartDate: null,
  searchEndDate: null,
  searchLocation: "ALL",
  searchTipoExperiencia: "ALL",
};

//REDUCER
const searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_START_DATE:
      return { ...state, startDate: action.payload };
    case SET_END_DATE:
      return { ...state, endDate: action.payload };
    case SET_LOCATION:
      return { ...state, location: action.payload };
    case SET_TIPO_EXPERIENCIA:
      return { ...state, tipoExperiencia: action.payload };
    case SET_SEARCH_START_DATE:
      return { ...state, searchStartDate: action.payload };
    case SET_SEARCH_END_DATE:
      return { ...state, searchEndDate: action.payload };
    case SET_SEARCH_LOCATION:
      return { ...state, searchLocation: action.payload };
    case SET_SEARCH_TIPO_EXPERIENCIA:
      return { ...state, searchTipoExperiencia: action.payload };
    case CLEAN_SEARCH:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default searchReducer;

//ACTIONS
export const set_start_date = (startDate) => ({
  type: SET_START_DATE,
  payload: startDate,
});

export const set_end_date = (endDate) => ({
  type: SET_END_DATE,
  payload: endDate,
});

export const set_location = (location) => ({
  type: SET_LOCATION,
  payload: location,
});

export const set_tipo_experiencia = (tipoExperiencia) => ({
  type: SET_TIPO_EXPERIENCIA,
  payload: tipoExperiencia,
});

export const set_search_start_date = (searchStartDate) => ({
  type: SET_SEARCH_START_DATE,
  payload: searchStartDate,
});

export const set_search_end_date = (searchEndDate) => ({
  type: SET_SEARCH_END_DATE,
  payload: searchEndDate,
});

export const set_search_location = (searchLocation) => ({
  type: SET_SEARCH_LOCATION,
  payload: searchLocation,
});

export const set_search_tipo_experiencia = (searchTipoExperiencia) => ({
  type: SET_SEARCH_TIPO_EXPERIENCIA,
  payload: searchTipoExperiencia,
});

export const clean_search = () => ({
  type: CLEAN_SEARCH,
});

//SELECTOR
const searchState = (state) => state.searchReducer;

export const makeSelectStartDate = createSelector(
  searchState,
  (search) => search.startDate
);

export const makeSelectEndDate = createSelector(
  searchState,
  (search) => search.endDate
);

export const makeSelectLocation = createSelector(
  searchState,
  (search) => search.location
);

export const makeSelectTipoExperiencia = createSelector(
  searchState,
  (search) => search.tipoExperiencia
);

export const makeSelectSearchStartDate = createSelector(
  searchState,
  (search) => search.searchStartDate
);

export const makeSelectSearchEndDate = createSelector(
  searchState,
  (search) => search.searchEndDate
);

export const makeSelectSearchLocation = createSelector(
  searchState,
  (search) => search.searchLocation
);

export const makeSelectSearchTipoExperiencia = createSelector(
  searchState,
  (search) => search.searchTipoExperiencia
);
