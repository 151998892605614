import { createSelector } from "reselect";

//CONSTANTES
const SET_TERMS_AND_CONDITIONS =
  "booker/verifyReservation/set_terms_and_conditions";
const CLEAN_VERIFY_RESERVATION =
  "booker/verifyReservation/clean_verify_reservation";
//ESTADO INICIAL
const initialState = {
  termsAndConditions: false,
};

//REDUCER
const verifyReservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TERMS_AND_CONDITIONS:
      return { ...state, termsAndConditions: action.payload };
    case CLEAN_VERIFY_RESERVATION:
      return { ...initialState };
    default:
      return state;
  }
};

export default verifyReservationReducer;

//ACTIONS
export const set_terms_and_conditions = (termsAndConditions) => ({
  type: SET_TERMS_AND_CONDITIONS,
  payload: termsAndConditions,
});

export const clean_verify_reservation = () => ({
  type: CLEAN_VERIFY_RESERVATION,
});

//SELECTORS
const verifyReservationState = (state) => state.verifyReservationReducer;

export const makeSelectTermsAndConditions = createSelector(
  verifyReservationState,
  (reservation) => reservation.termsAndConditions
);
