import { createSelector } from 'reselect'

//CONSTANTES
const GET_POSITION = 'booker/position/get_position'

// ESTADO INICIAL
const initialState = {
  position: {},
}

//REDUCER
const positionReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_POSITION:
      return { ...state, position: action.payload }
    default:
      return state
  }
}

export default positionReducer

//ACTIONS
export const get_position = (position) => ({
  type: GET_POSITION,
  payload: position,
})

//SELECTORS
const positionState = (state) => state.positionReducer
export const makePosition = createSelector(
  positionState,
  (position) => position,
)