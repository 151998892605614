import { createSelector } from 'reselect'

//CONSTANTES
const SET_ACTIVE_STEP = 'booker/reservationDetails/active_step'
const SET_START_DATE = 'booker/reservationDetails/set_start_date'
const SET_END_DATE = 'booker/reservationDetails/set_end_date'
const SET_GUEST_AMOUNT = 'booker/reservationDetails/set_guest_amount'
const SET_TOTAL_PRICE = 'booker/reservationDetails/set_total_price'
const SET_CHILD_AMOUNT = 'booker/reservationDetails/set_child_amount'
const SET_SINGLE_DATE = 'booker/reservationDetails/set_single_date'
const SET_EXIT_DATE = 'booker/reservationDetails/set_exit_date'
const SET_TIME = 'booker/reservationDetails/set_time'
const SET_EXIT_TIME = 'booker/reservationDetails/set_exit_time'
const SET_HOURS_AMOUNT = 'booker/reservationDetails/set_hours_amount'
const SET_EXTRA_PERSONS = 'booker/reservationDetails/set_extra_persons'
const SET_TIME_SELECT = 'booker/reservationDetails/set_time_select'
const SET_HOTEL_HOUR = 'booker/reservationDetails/set_hotel_hour'
const CLEAN_RESERVATION_DETAILS =
  'booker/reservationDetails/clean_reservation_details'
const SET_PARTIAL_PRICE = 'booker/reservationDetails/set_partial_price'
const SET_ADDONS_PRICE = 'booker/reservationDetails/set_addons_price'
const SET_ADDONS_PERSONS_PRICE =
  'booker/reservationDetails/set_addons_persons_price'
const SET_RESERVATION_PRICE = 'booker/reservationDetails/set_reservation_price'
const SET_SELECTED_ADDONS = 'booker/reservationDetails/set_selected_addons'
const SET_SERVICE = 'booker/reservationDetails/set_service'
const SET_SINGLE_LIMIT = 'booker/reservationDetails/set_single_limit'
const SET_CODE = 'booker/reservationDetails/set_code'
const SET_IS_GIFT_CARD = 'booker/reservationDetails/set_is_gift_card'

// ESTADO INICIAL
const initialState = {
  activeStep: 0,
  startDate: null,
  endDate: null,
  guestAmount: 0,
  totalPrice: 0,
  childAmount: 0,
  singleDate: null,
  exitDate: null,
  time: '',
  exitTime: '',
  hoursAmount: 0,
  extraPersons: 0,
  timeSelect: [],
  hotelHour: '',
  partialPrice: 0,
  addonsPrice: 0,
  addonsPersonsPrice: 0,
  reservationPrice: 0,
  selectedAddons: [],
  service: {},
  singleLimit: 0,
  code: '',
  giftCard: false,
}

//REDUCER
const reservationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload }
    case SET_START_DATE:
      return { ...state, startDate: action.payload }
    case SET_END_DATE:
      return { ...state, endDate: action.payload }
    case SET_GUEST_AMOUNT:
      return { ...state, guestAmount: action.payload }
    case SET_TOTAL_PRICE:
      return { ...state, totalPrice: action.payload }
    case SET_CHILD_AMOUNT:
      return { ...state, childAmount: action.payload }
    case SET_SINGLE_DATE:
      return { ...state, singleDate: action.payload }
      case SET_EXIT_DATE:
        return { ...state, exitDate: action.payload }
    case SET_TIME:
      return { ...state, time: action.payload }
      case SET_EXIT_TIME:
        return { ...state, exitTime: action.payload }
      case SET_HOURS_AMOUNT:
        return { ...state, hoursAmount: action.payload }
        case SET_EXTRA_PERSONS:
          return { ...state, extraPersons: action.payload }
    case SET_TIME_SELECT:
      return { ...state, timeSelect: action.payload }
    case SET_HOTEL_HOUR:
      return { ...state, hotelHour: action.payload }
    case CLEAN_RESERVATION_DETAILS:
      return { ...initialState }
    case SET_PARTIAL_PRICE:
      return { ...state, partialPrice: action.payload }
    case SET_ADDONS_PRICE:
      return { ...state, addonsPrice: action.payload }
    case SET_ADDONS_PERSONS_PRICE:
      return { ...state, addonsPersonsPrice: action.payload }
    case SET_RESERVATION_PRICE:
      return { ...state, reservationPrice: action.payload }
    case SET_SELECTED_ADDONS:
      return { ...state, selectedAddons: action.payload }
    case SET_SERVICE:
      return { ...state, service: action.payload }
    case SET_SINGLE_LIMIT:
      return { ...state, singleLimit: action.payload }
    case SET_CODE:
      return { ...state, code: action.payload }
    case SET_IS_GIFT_CARD:
      return { ...state, giftCard: action.payload }
    default:
      return state
  }
}

export default reservationReducer

//ACTIONS
export const set_active_step = (step) => ({
  type: SET_ACTIVE_STEP,
  payload: step,
})

export const set_start_date = (startDate) => ({
  type: SET_START_DATE,
  payload: startDate,
})

export const set_end_date = (endDate) => ({
  type: SET_END_DATE,
  payload: endDate,
})

export const set_guest_amount = (guestAmount) => ({
  type: SET_GUEST_AMOUNT,
  payload: guestAmount,
})

export const set_total_price = (totalPrice) => ({
  type: SET_TOTAL_PRICE,
  payload: totalPrice,
})

export const set_child_amount = (childAmount) => ({
  type: SET_CHILD_AMOUNT,
  payload: childAmount,
})

export const set_single_date = (singleDate) => ({
  type: SET_SINGLE_DATE,
  payload: singleDate,
})

export const set_exit_date = (exitDate) => ({
  type: SET_EXIT_DATE,
  payload: exitDate,
})

export const set_time = (time) => ({
  type: SET_TIME,
  payload: time,
})

export const set_exit_time = (exitTime) => ({
  type: SET_EXIT_TIME,
  payload: exitTime,
})

export const set_hours_amount = (hoursAmount) => ({
  type: SET_HOURS_AMOUNT,
  payload: hoursAmount,
})

export const set_extra_persons = (extraPersons) => ({
  type: SET_EXTRA_PERSONS,
  payload: extraPersons,
})


export const set_time_select = (timeSelect) => ({
  type: SET_TIME_SELECT,
  payload: timeSelect,
})

export const set_hotel_hour = (hotelHour) => ({
  type: SET_HOTEL_HOUR,
  payload: hotelHour,
})

export const clean_reservation_details = () => ({
  type: CLEAN_RESERVATION_DETAILS,
})

export const set_partial_price = (partialPrice) => ({
  type: SET_PARTIAL_PRICE,
  payload: partialPrice,
})

export const set_addons_price = (addonsPrice) => ({
  type: SET_ADDONS_PRICE,
  payload: addonsPrice,
})

export const set_addons_persons_price = (addonsPersonsPrice) => ({
  type: SET_ADDONS_PERSONS_PRICE,
  payload: addonsPersonsPrice,
})

export const set_reservation_price = (reservationPrice) => ({
  type: SET_RESERVATION_PRICE,
  payload: reservationPrice,
})

export const set_selected_addons = (selectedAddons) => ({
  type: SET_SELECTED_ADDONS,
  payload: selectedAddons,
})

export const set_service = (service) => ({
  type: SET_SERVICE,
  payload: service,
})

export const set_single_limit = (singleLimit) => ({
  type: SET_SINGLE_LIMIT,
  payload: singleLimit,
})

export const set_code = (code) => ({
  type: SET_CODE,
  payload: code,
})

export const set_is_gift_card = (giftCard) => ({
  type: SET_IS_GIFT_CARD,
  payload: giftCard,
})

//SELECTORS
const reservationState = (state) => state.reservationReducer

export const makeSelectActiveStep = createSelector(
  reservationState,
  (reservation) => reservation.activeStep,
)

export const makeSelectStartDate = createSelector(
  reservationState,
  (reservation) => reservation.startDate,
)

export const makeSelectEndDate = createSelector(
  reservationState,
  (reservation) => reservation.endDate,
)

export const makeSelectGuestAmount = createSelector(
  reservationState,
  (reservation) => reservation.guestAmount,
)

export const makeSelectTotalPrice = createSelector(
  reservationState,
  (reservation) => reservation.totalPrice,
)

export const makeSelectChildAmount = createSelector(
  reservationState,
  (reservation) => reservation.childAmount,
)

export const makeSelectSingleDate = createSelector(
  reservationState,
  (reservation) => reservation.singleDate,
)

export const makeSelectExitDate = createSelector(
  reservationState,
  (reservation) => reservation.exitDate,
)


export const makeSelectTime = createSelector(
  reservationState,
  (reservation) => reservation.time,
)

export const makeSelectExitTime = createSelector(
  reservationState,
  (reservation) => reservation.exitTime,
)

export const makeSelectHoursAmount = createSelector(
  reservationState,
  (reservation) => reservation.hoursAmount,
)

export const makeSelectExtraPersons = createSelector(
  reservationState,
  (reservation) => reservation.extraPersons,
)

export const makeSelectHotelHour = createSelector(
  reservationState,
  (reservation) => reservation.hotelHour,
)

export const makeSelectTimeSelect = createSelector(
  reservationState,
  (reservation) => reservation.timeSelect,
)

export const makeSelectPartialPrice = createSelector(
  reservationState,
  (reservation) => reservation.partialPrice,
)

export const makeSelectAddonsPrice = createSelector(
  reservationState,
  (reservation) => reservation.addonsPrice,
)

export const makeSelectAddonsPersonsPrice = createSelector(
  reservationState,
  (reservation) => reservation.addonsPersonsPrice,
)

export const makeSelectReservationPrice = createSelector(
  reservationState,
  (reservation) => reservation.reservationPrice,
)

export const makeSelectSelectedAddons = createSelector(
  reservationState,
  (reservation) => reservation.selectedAddons,
)

export const makeSelectService = createSelector(
  reservationState,
  (reservation) => reservation.service,
)

export const makeSelectSingleLimit = createSelector(
  reservationState,
  (reservation) => reservation.singleLimit,
)

export const makeSelectCode = createSelector(
  reservationState,
  (reservation) => reservation.code,
)

export const makeSelectGiftCard= createSelector(
  reservationState,
  (reservation) => reservation.giftCard,
)
