import { createStore, combineReducers } from 'redux'
import searchReducer from './ducks/search'
import experiencesReducer from './ducks/experiences'
import reservationReducer from './ducks/reservationDetails'
import reservationInfoReducer from './ducks/reservationInfo'
import verifyReservationReducer from './ducks/verifyReservation'
import restaurantMenuReducer from './ducks/restaurantMenu'
import signupReducer from './ducks/signup'
import passengersReducer from './ducks/passengers'
import positionReducer from './ducks/position'

const reducers = combineReducers({
  searchReducer,
  experiencesReducer,
  reservationReducer,
  reservationInfoReducer,
  verifyReservationReducer,
  restaurantMenuReducer,
  signupReducer,
  passengersReducer,
  positionReducer
})

export default createStore(reducers)
