import { createSelector } from "reselect";

//CONSTANTES
const SET_NAME = "booker/reservationInfo/set_name";
const SET_LASTNAME = "booker/reservationInfo/set_last_name";
const SET_PHONE = "booker/reservationInfo/set_phone";
const SET_EMAIL = "booker/reservationInfo/set_email";
const SET_GIFTED_NAME = "booker/reservationInfo/set_gifted_name";
const SET_GIFTED_LASTNAME = "booker/reservationInfo/set_gifted_last_name";
const SET_GIFTED_PHONE = "booker/reservationInfo/set_gifted_phone";
const SET_GIFTED_EMAIL = "booker/reservationInfo/set_gifted_email";
const CLEAN_RESERVATION_INFO = "booker/reservationInfo/clean_reservation_info";

//ESTADO INICIAL

const initialState = {
  name: "",
  lastName: "",
  phone: "",
  email: "",
  giftedName: "",
  giftedLastName: "",
  giftedPhone: "",
  giftedEmail: "",
};

//REDUCER

const reservationInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_NAME:
      return { ...state, name: action.payload };
    case SET_LASTNAME:
      return { ...state, lastName: action.payload };
    case SET_PHONE:
      return { ...state, phone: action.payload };
    case SET_EMAIL:
      return { ...state, email: action.payload };
    case SET_GIFTED_NAME:
        return { ...state, giftedName: action.payload };
    case SET_GIFTED_LASTNAME:
        return { ...state, giftedLastName: action.payload };
    case SET_GIFTED_PHONE:
        return { ...state, giftedPhone: action.payload };
    case SET_GIFTED_EMAIL:
        return { ...state, giftedEmail: action.payload };
    case CLEAN_RESERVATION_INFO:
      return { ...initialState };
    default:
      return state;
  }
};

export default reservationInfoReducer;

//ACTIONS
export const set_email = (email) => ({
  type: SET_EMAIL,
  payload: email,
});

export const set_name = (name) => ({
  type: SET_NAME,
  payload: name,
});

export const set_last_name = (lastname) => ({
  type: SET_LASTNAME,
  payload: lastname,
});

export const set_phone = (phone) => ({
  type: SET_PHONE,
  payload: phone,
});

export const set_gifted_email = (giftedEmail) => ({
  type: SET_GIFTED_EMAIL,
  payload: giftedEmail,
});

export const set_gifted_name = (giftedName) => ({
  type: SET_GIFTED_NAME,
  payload: giftedName,
});

export const set_gifted_last_name = (giftedLastname) => ({
  type: SET_GIFTED_LASTNAME,
  payload: giftedLastname,
});

export const set_gifted_phone = (giftedPhone) => ({
  type: SET_GIFTED_PHONE,
  payload: giftedPhone,
});

export const clean_reservation_info = () => ({
  type: CLEAN_RESERVATION_INFO,
});

//SELECTORS
const reservationInfoState = (state) => state.reservationInfoReducer;

export const makeSelectName = createSelector(
  reservationInfoState,
  (reservation) => reservation.name
);

export const makeSelectLastName = createSelector(
  reservationInfoState,
  (reservation) => reservation.lastName
);

export const makeSelectPhone = createSelector(
  reservationInfoState,
  (reservation) => reservation.phone
);

export const makeSelectEmail = createSelector(
  reservationInfoState,
  (reservation) => reservation.email
);

export const makeSelectGiftedName = createSelector(
  reservationInfoState,
  (reservation) => reservation.giftedName
);

export const makeSelectGiftedLastName = createSelector(
  reservationInfoState,
  (reservation) => reservation.giftedLastName
);

export const makeSelectGiftedPhone = createSelector(
  reservationInfoState,
  (reservation) => reservation.giftedPhone
);

export const makeSelectGiftedEmail = createSelector(
  reservationInfoState,
  (reservation) => reservation.giftedEmail
);
