import React, { Suspense, lazy } from 'react'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

const Explorar = lazy(() => import('./views/Explorar/Explorar'))
const Payment = lazy(() => import('./views/Payment/Payment'))
const Category = lazy(() => import('./views/Category/Category'))

/* Probablemente entre los mas pesados */
const Details = lazy(() => import('./views/Details/Details'))

/* Probablemente entre los mas pesados */
const DetailsWithSlug = lazy(() =>
  import('./views/DetailsWithSlug/DetailsWithSlug'),
)
const CompanyProfile = lazy(() =>
  import('./views/CompanyProfile/CompanyProfile'),
)
const TermsAndConditions = lazy(() =>
  import('./views/TermsAndConditions/TermsAndConditions'),
)
const RestaurantMenu = lazy(() =>
  import('./views/RestaurantMenu/RestaurantMenu'),
)
const MakeABookerSignUp = lazy(() =>
  import('./views/MakeABookerSignUp/MakeABookerSignUp'),
)
const ReservationDetails = lazy(() =>
  import('./views/ReservationDetails/ReservationDetails'),
)
const PageNotFound = lazy(() => import('./views/PageNotFound/PageNotFound'))
const PassengerFile = lazy(() => import('./views/PassengerFile/PassengerFile'))
const PointOfSales = lazy(() => import('./views/PointOfSales/PointOfSales'))
const Marketplace = lazy(() => import('./views/Marketplace/Marketplace'))
const ReviewForm = lazy(() => import('./views/ReviewForm/ReviewForm'))
const QRMenu = lazy(() => import('./views/QRMenu/QRMenu'))
const FlowNotPayed = lazy(() => import('./views/FlowNotPayed/FlowNotPayed'))
const PaymentFeedbackWebpay = lazy(() =>
  import('./components/PaymenFeedback/PaymentFeedbackWebpay'),
)
const ViewCheckout = lazy(() => import('./views/Checkout/ViewCheckout'))
const PdfComponent = lazy(() =>
  import('./components/PDFComponent/PdfComponent'),
)

function App() {
  return (
    <>
      <Router>
        <Suspense fallback={null}>
          <Switch>
            <Route exact path="/" component={Explorar} />
            <Route path="/404" component={PageNotFound} />
            <Route
              path="/review/:agencyId/:experienceId/:reservationId"
              component={ReviewForm}
            />
            <Route
              exact
              path="/marketplace/:slug/:agencyId"
              component={CompanyProfile}
            />
            <Route
              exact
              path="/marketplace/:slug/:agencyId/:experienceId/:slugExperience"
              component={Details}
            />
            <Route exact path="/marketplace/:slug" component={Marketplace} />
            <Route
              exact
              path="/qrmenu/:agencyId/:experienceId/:menuId"
              component={QRMenu}
            />

            <Route
              exact
              path="/pos/:agencyId/:experienceId/:token"
              component={PointOfSales}
            />
            <Route
              exact
              path="/reservation/detail/:agencyId/:experienceId/:reservationId/:admin"
              component={ReservationDetails}
            />
            <Route
              exact
              path="/checkoutpay/:agencyId/:experienceId"
              component={ViewCheckout}
            />
            <Route exact path="/pdfcomponent" component={PdfComponent} />
            <Route
              exact
              path="/passenger/file/:agencyId/:experienceId/:reservationId/:passengerId"
              component={PassengerFile}
            />
            <Route
              path="/terms-and-conditions/:agencyId"
              component={TermsAndConditions}
            />
            <Route
              exact
              path="/feedbak/webpay/:token"
              component={PaymentFeedbackWebpay}
            />
            <Route
              exact
              path="/payments/reservation/:reservationId/paidout/:flowOrder/:agencyId/:experienceId"
              component={Payment}
            />
            <Route
              exact
              path="/payments/reservation/:reservationId/notpayed/:agencyId/:experienceId"
              component={FlowNotPayed}
            />
            <Route exact path="/payments/:paymentStatus" component={Payment} />
            <Route
              exact
              path="/company/:agencyId/:experienceId/menu"
              component={RestaurantMenu}
            />
            <Route
              exact
              path="/make-a-booker/signup"
              component={MakeABookerSignUp}
            />
            <Route exact path="/search/:category" component={Category} />
            <Route exact path="/:agencyId" component={CompanyProfile} />
            <Route
              exact
              path="/:agencyId/:experienceId/:slug"
              component={Details}
            />
            <Route
              exact
              path="/:slugAgency/:slugExperience"
              component={DetailsWithSlug}
            />
            <Route
              exact
              path="/:agencyId/:experienceId/:reservationId/:couponId"
              component={Details}
            />
            <Route
              exact
              path="/:agencyId/:experienceId/:reservationId"
              component={Details}
            />

            <Redirect to="/404" />
          </Switch>
        </Suspense>
      </Router>
    </>
  )
}

export default App
