import { createSelector } from 'reselect'

//CONSTANTES
const SET_DIALOG_OPEN = 'booker/restaurantMenu/set_dialog_open'
const SET_SELECTED_PRODUCT = 'booker/restaurantMenu/set_selected_product'
const SET_ORDER_PRODUCTS = 'booker/restaurantMenu/set_order_products'
const SET_TOTAL_ORDER_PRICE = 'booker/restaurantMenu/set_total_order_price'
const SET_RESTAURANT_INFO = 'booker/restaurantMenu/set_restaurant_info'
const SET_RESTAURANT_OPEN = 'booker/restaurantMenu/set_restaurant_open'
const SET_ORDER_INDICATIONS = 'booker/restaurantMenu/set_order_INDICATIONS'
const SET_DELIVERY_ADDRESS = 'booker/restaurantMenu/set_delivery_address'
const SET_DISPATCH_METHOD = 'booker/restaurantMenu/set_dispatch_method'
const CLEAN_RESTAURANT_MENU = 'booker/restaurantMenu/clean_restaurant_menu'

//ESTADO INICIAL
const initialState = {
  isDialogOpen: false,
  selectedProduct: {},
  orderProducts: [],
  totalOrderPrice: 0,
  restaurantInfo: {},
  restaurantOpen: true,
  orderIndications: '',
  deliveryAddress: {},
  dispatchMethod: '',
}

//REDUCER
const restaurantMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_DIALOG_OPEN:
      return { ...state, isDialogOpen: action.payload }
    case SET_SELECTED_PRODUCT:
      return { ...state, selectedProduct: action.payload }
    case SET_ORDER_PRODUCTS:
      return { ...state, orderProducts: action.payload }
    case SET_TOTAL_ORDER_PRICE:
      return { ...state, totalOrderPrice: action.payload }
    case SET_RESTAURANT_INFO:
      return { ...state, restaurantInfo: action.payload }
    case SET_RESTAURANT_OPEN:
      return { ...state, restaurantOpen: action.payload }
    case SET_ORDER_INDICATIONS:
      return { ...state, orderIndications: action.payload }
    case SET_DELIVERY_ADDRESS:
      return { ...state, deliveryAddress: action.payload }
    case SET_DISPATCH_METHOD:
      return { ...state, dispatchMethod: action.payload }
    case CLEAN_RESTAURANT_MENU:
      return { ...initialState }
    default:
      return state
  }
}

export default restaurantMenuReducer

//ACTIONS
export const set_dialog_open = (option) => ({
  type: SET_DIALOG_OPEN,
  payload: option,
})

export const set_selected_product = (product) => ({
  type: SET_SELECTED_PRODUCT,
  payload: product,
})

export const set_order_products = (order) => ({
  type: SET_ORDER_PRODUCTS,
  payload: order,
})

export const set_total_order_price = (totalOrderPrice) => ({
  type: SET_TOTAL_ORDER_PRICE,
  payload: totalOrderPrice,
})

export const set_restaurant_info = (restaurantInfo) => ({
  type: SET_RESTAURANT_INFO,
  payload: restaurantInfo,
})

export const set_restaurant_open = (restaurantOpen) => ({
  type: SET_RESTAURANT_OPEN,
  payload: restaurantOpen,
})

export const set_order_indications = (orderIndications) => ({
  type: SET_ORDER_INDICATIONS,
  payload: orderIndications,
})

export const set_delivery_address = (deliveryAddress) => ({
  type: SET_DELIVERY_ADDRESS,
  payload: deliveryAddress,
})

export const set_dispatch_method = (dispatchMethod) => ({
  type: SET_DISPATCH_METHOD,
  payload: dispatchMethod,
})

export const clean_restaurant_menu = () => ({
  type: CLEAN_RESTAURANT_MENU,
})

//SELECTORS
const restaurantMenuState = (state) => state.restaurantMenuReducer

export const makeSelectDialogOpen = createSelector(
  restaurantMenuState,
  (restaurant) => restaurant.isDialogOpen,
)

export const makeSelectSelectedProduct = createSelector(
  restaurantMenuState,
  (restaurant) => restaurant.selectedProduct,
)

export const makeSelectOrderProducts = createSelector(
  restaurantMenuState,
  (restaurant) => restaurant.orderProducts,
)

export const makeSelectTotalOrderPrice = createSelector(
  restaurantMenuState,
  (restaurant) => restaurant.totalOrderPrice,
)

export const makeSelectRestaurantInfo = createSelector(
  restaurantMenuState,
  (restaurant) => restaurant.restaurantInfo,
)

export const makeSelectRestaurantOpen = createSelector(
  restaurantMenuState,
  (restaurant) => restaurant.restaurantOpen,
)

export const makeSelectOrderIndications = createSelector(
  restaurantMenuState,
  (restaurant) => restaurant.orderIndications,
)

export const makeSelectDeliveryAddress = createSelector(
  restaurantMenuState,
  (restaurant) => restaurant.deliveryAddress,
)

export const makeSelectDispatchMethod = createSelector(
  restaurantMenuState,
  (restaurant) => restaurant.dispatchMethod,
)
